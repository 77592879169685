<div class="card">
    <div class="card-title m-2 text-center">
      {{title}}
    </div>
    <div class="row">
      <list-spinner class="center-body p-3" [ngClass]="{'position-absolute': model?.data?.Items.length > 0}"
        [showSpinner]="Loading" [length]="model?.data?.Items.length"></list-spinner>
      <div class="table-responsive" [ngClass]="{'blur-content': Loading }"
        *ngIf="model?.data && model?.data.Items.length > 0">
        <table class="table table-hover mb-0">
          <thead>
            <tr class="table-warning">
              <th class="text-center">#</th>
              <th>Müşteri</th>
              <th>Satış</th>
              <th>Alış</th>
              <th>Kur</th>
              <th>Anşl. Tar.</th>
            </tr>
            <!-- -->
          </thead>
          <tbody>
            <tr *ngFor="let item of model.data.Items; let index = index" routerLink="/exchange/detail/{{item.id}}" style="cursor: pointer;">
              <td class="text-center">{{index + 1}}.</td>
              <td> <a routerLink="/customer/detail/{{item.customerId}}" href="javascript: void(0);" class="text-dark">{{
                  item.customerName }} </a></td>
              <td class="table-danger">
                <div class="d-flex justify-content-between">
                  <div>{{ item.exchangeAmount | currencyAmount: item.exchangeCurrency}}</div>
                  <div> {{item.exchangeCurrency | priceCurrency : item.exchangeSubType }} <span *ngIf="item.exchangeCurrency == Currency.Gold">({{item.exchangeMilyem}})</span> </div>
                </div>
              </td>
              <td class="table-success">
                <div class="d-flex justify-content-between">
                  <div>{{ item.paymentAmount | currencyAmount: item.paymentCurrency}}</div>
                  <div> {{item.paymentCurrency | priceCurrency : item.paymentSubType }} <span *ngIf="item.paymentCurrency == Currency.Gold">({{item.paymentMilyem}})</span> </div>
                </div>
                  <td class="text-end">{{ item.parity | currencyAmount: null: null: true }}</td>
              <td class="text-center"><i class="fas fa-calendar"
                  ngbTooltip="{{ item.agreedDate | date:'dd.MM.y HH:mm'}}"></i></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="model?.data && model?.data?.TotalItemCount > 0">
        <app-pagination [blurOn]="Loading" [pageInfo]="model.data" [pageRequest]="request"
          (requestEvent)="reloadData()">
        </app-pagination>
      </div>
    </div>
</div>