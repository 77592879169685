import { Pipe, PipeTransform } from "@angular/core";
import { Currency } from "src/app/models/enums/currency";

@Pipe({
  name: "currencyCastEnum",
})
export class CurrencyCastEnumPipe implements PipeTransform {
  transform(currencyText: any, ...args: unknown[]): Currency {
    let castedCurrency: Currency;

    switch (currencyText) {
      case "Tl":
        castedCurrency = Currency.Tl;
        break;
      case "Usd":
        castedCurrency = Currency.Usd;
        break;
      case "Euro":
        castedCurrency = Currency.Euro;
        break;
      case "Gold":
        castedCurrency = Currency.Gold;
        break;
      case "Silver":
        castedCurrency = Currency.Silver;
        break;
      case "Other":
        castedCurrency = Currency.Other;
        break;
    }

    return castedCurrency;
  }
}
