import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from 'src/app/models/enums/currency';

@Pipe({
  name: 'currencyIcon'
})
export class CurrencyIconPipe implements PipeTransform {

  transform(currency: Currency, amount?: number  , ...args: unknown[]): unknown {
    let val = Number(String(currency)) as Currency;
    switch (val) {
      case Currency.Tl:
        return amount ?  '<i class="fas fa-2x fa-lira-sign text-danger align-middle pb-2 me-2"></i> '+ amount : '<i class="fas fa-lira-sign text-danger align-middle pb-2 me-2"></i>';
      case Currency.Usd:
        return amount ? '<i class="fas fa-2x fa-dollar-sign text-success align-middle pb-2 me-2"></i> '+ amount  : '<i class="fas fa-dollar-sign text-success align-middle pb-2 me-2"></i>';
      case Currency.Euro:
        return amount ? '<i class="fas fa-2x fa-euro-sign text-info align-middle pb-2 me-2"></i> '+ amount : '<i class="fas fa-euro-sign text-info align-middle pb-2 me-2"></i>';
      case Currency.Gold:
        return amount ? '<i class="fas fa-coins text-warning align-middle pb-2 me-2"></i> '+ amount : '<i class="fas fa-coins text-warning align-middle pb-2 me-2"></i>';
      case Currency.Accessories:
        return amount ? '<i class="fas fa-2x fa-gem text-muted align-middle pb-2 me-2"></i> '+ amount : '<i class="fas fa-gem text-muted align-middle pb-2 me-2"></i>';
      case Currency.OtherAccessories:
        return amount ? '<i class="fas fa-2x fa-gem text-muted align-middle pb-2 me-2"></i> '+ amount : '<i class="fas fa-gem text-muted align-middle pb-2 me-2"></i>';
      case Currency.Silver:
        return amount ? '<i class="fas fa-coins text-dark align-middle pb-2 me-2"></i> '+ amount : '<i class="fas fa-coins text-dark align-middle pb-2 me-2"></i>';
      case Currency.Other:
        return amount ? '<i class="fas fa-money-bill text-info align-middle pb-2 me-2"></i> '+ amount : '<i class="fas fa-money-bill text-info align-middle pb-2 me-2"></i>';
        default:
        return '-'
        break;
    }
  }

}
