import { Pipe, PipeTransform } from "@angular/core";
import { ExchangeCostState } from "src/app/models/enums/exchange-cost-state";

@Pipe({
  name: "costStateIcon",
})
export class CostStateIconPipe implements PipeTransform {
  transform(state: ExchangeCostState): string {
    switch (state) {
      case ExchangeCostState.None:
        return '<i class="fas fa-question-circle text-light"></i>';
      case ExchangeCostState.Down:
        return '<i class="fas fa-chevron-circle-down text-success"></i>';
      case ExchangeCostState.Up:
        return '<i class="fas fa-chevron-circle-up text-danger"></i>';
      case ExchangeCostState.Equal:
        return '<i class="fas fa-minus-square text-warning"></i>';
    }
  }
}
