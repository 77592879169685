import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllCaseBalancesExportComponent } from './all-case-balances-export.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    AllCaseBalancesExportComponent
  ],
  imports: [
    CommonModule,
    NgbDatepickerModule,
    FormsModule,
  ],
  exports: [
    AllCaseBalancesExportComponent
  ]
})
export class AllCaseBalancesExportModule { }
