import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductImportModule } from './product-import/product-import.module';
import { ProductStocksExportModule } from './product-stocks-export/product-stocks-export.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ProductImportModule,
    ProductStocksExportModule
  ],
})
export class ProductsModule { }
