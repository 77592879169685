<h5 class="header text-center"> Virman Özeti </h5>
<div *ngIf="(response$ | async) as model">
  <ng-container *ngIf="model?.data?.items?.length > 0">
    <div class="d-flex justify-content-between header-info">
      <span><strong> İşlemi Yapan: </strong> {{model.data.createrName}}</span>
      <span><strong> Tarih: </strong> {{model.data.operationDate | date:'dd/MM/yyyy HH:mm'}}</span>
    </div>
    <div class="d-flex justify-content-between header-info">
      <span><strong> Gönderen: </strong> {{model.data.sourceCustomerName}} </span>
      <span><strong> Alan: </strong> {{model.data.destinationCustomerName}} </span>
    </div>
    <table class="table mb-0 mt-3">
      <thead>
        <tr>
          <th class="text-center">ISL</th>
          <th class="text-center">KODU</th>
          <th class="text-center">MİKTAR</th>
          <th class="text-center">MİLYEM</th>
          <th class="text-center">HAS/FİY</th>
          <th class="text-center">TUTAR</th>
          <th class="text-center">BİRİM</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of model.data.items">
          <ng-container *ngIf="item.type == Type.Debt">
            <td><span *ngIf="item.type == Type.Debt">C</span> <span *ngIf="item.type == Type.Receivable">G</span>
            </td>
            <td class="text-center">{{item.subType | subTypeShort: item.currency}}</td>
            <td class="text-center">{{item.amount | currencyAmount: item.currency: item.subType: true }}</td>
            <td class="text-center">{{item.milyem | receiptMilyem}}</td>
            <td class="text-center">1.000</td>
            <td class="text-center">{{item.amount | currencyAmount: item.currency: item.subType: true }}</td>
            <td class="text-center">{{item.subType | subTypeShort: item.currency}}</td>
          </ng-container>
          </tr>
      </tbody>
    </table>
    <ng-container *ngIf="request.includeSourceCustomerBalance">
      <div class="customer-balance-summary mt-3 mb-0"> {{model.data.sourceCustomerName}} BAKİYE </div>
      <table class="table mb-0 mt-1">
        <thead class="border-bottom">
          <tr>
            <th class="text-center">BİRİM</th>
            <th class="text-center">ÖNCEKİ BAKİYE</th>
            <th class="text-center">GİRİŞ/ÇIKIŞ</th>
            <th class="text-center">SON BAKİYE</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of model.data.sourceCustomerBalances">
            <td class="text-center">{{item.subType | subTypeShort: item.currency }}</td>
            <td class="text-center">{{item.oldBalance | currencyAmount: item.currency: item.subType: true }}</td>
            <td class="text-center">{{item.operationAmount | currencyAmount: item.currency: item.subType: true }}</td>
            <td class="text-center">{{item.newBalance | currencyAmount: item.currency: item.subType: true }}</td>
          </tr>
        </tbody>
      </table>
    </ng-container>
    <ng-container *ngIf="request.includeDestinationCustomerBalance">
      <div class="customer-balance-summary mt-3 mb-0"> {{model.data.destinationCustomerName}} BAKİYE
      </div>
      <table class="table mb-0 mt-1">
        <thead class="border-bottom">
          <tr>
            <th class="text-center">BİRİM</th>
            <th class="text-center">ÖNCEKİ BAKİYE</th>
            <th class="text-center">GİRİŞ/ÇIKIŞ</th>
            <th class="text-center">SON BAKİYE</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of model.data.destinationCustomerBalances">
            <td class="text-center">{{item.subType | subTypeShort: item.currency }}</td>
            <td class="text-center">{{item.oldBalance | currencyAmount: item.currency: item.subType: true }}</td>
            <td class="text-center">{{item.operationAmount | currencyAmount: item.currency: item.subType: true }}</td>
            <td class="text-center">{{item.newBalance | currencyAmount: item.currency: item.subType: true }}</td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </ng-container>
  <div class="text-center m-5" *ngIf="model.data.items.length == 0">
    <span><i class="fa fa-exclamation-circle"></i> Hesap hareketi bulunamadı.</span>
  </div>
</div>
<p class="sarraf">sarraf.io</p>
<div class="groupId text-center">Bilgilendirme amaçlıdır.<br> Resmi belge olarak kullanılamaz. Mali değeri yoktur.</div>
<div class="row mt-4"></div>
