import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'costAmount'
})
export class CostAmountPipe implements PipeTransform {

  transform(value: number, decimals: number = 6): string {
    if (value === null || value === undefined || value==0) return '';
    let formattedValue = value.toFixed(decimals);
    formattedValue = parseFloat(formattedValue).toString();
    formattedValue = formattedValue.replace('.', ',');
    const parts = formattedValue.split(',');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join(',');
  }

}
