import { Pipe, PipeTransform } from "@angular/core";
import { Currency } from "src/app/models/enums/currency";
import { CurrencySubTypes } from "src/app/models/enums/currency-sub-types";

@Pipe({
  name: "currencyWeight",
})
export class CurrencyWeightPipe implements PipeTransform {
  transform(currency: Currency, subType?: any): string {
    currency = parseInt(String(currency)) as Currency;
    let silverWeight;
    if (currency == Currency.Silver && !subType) silverWeight = "gr";
    if (subType == "Granular" || subType == CurrencySubTypes.Granular)
      silverWeight = "gr";
    if (subType == "Ingot" || subType == CurrencySubTypes.Ingot)
      silverWeight = "adet";

    switch (currency) {
      case Currency.Gold:
        return "gr";
      case Currency.Silver:
        return silverWeight;
      case Currency.Accessories:
      case Currency.OtherAccessories:
        if (
          subType != undefined &&
          (subType == CurrencySubTypes.Bilezik14 ||
            subType == CurrencySubTypes.Bilezik18 ||
            subType == CurrencySubTypes.Bilezik22)
        )
          return "gr";
        return "adet";
      case Currency.Tl:
        return "Lira";
      case Currency.Euro:
        return "Euro";
      case Currency.Usd:
        return "Dolar";
      case Currency.Other:
        return "";
    }
  }
}
