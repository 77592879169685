import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExchangeListMiniComponent } from './exchange-list-mini.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule as SharedComponentsModule } from 'src/app/shared/components/components.module';
import { NgSelectModule } from '@ng-select/ng-select';

import { NgbDropdownModule, NgbDatepickerModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask'
import { CoreModule } from 'src/app/core/core.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    ExchangeListMiniComponent
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    CoreModule,
    RouterModule,
    SharedComponentsModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    PipesModule,
    FormsModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [ExchangeListMiniComponent]
})
export class ExchangeListMiniModule { }
