import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DataService } from "src/app/services/data.service";
import { Parity } from "src/app/models/exchange/parity";
import { SelectItem } from "src/app/core/models/responses/select-item";
import { CustomerFilter } from "src/app/models/filters/customer-filter";
import { concat, Observable, of, Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  tap,
} from "rxjs/operators";
import { Customer } from "src/app/models/customer/customer";
import { OperationType } from "src/app/models/enums/operation-type";
import { CaseFilter } from "src/app/models/filters/case-filter";
import { Branch } from "src/app/models/branch/branch";
import { BranchBalanceRequest } from "src/app/models/branch/branch-balance-request";
import { AccountBalanceDTO } from "src/app/models/account/account-balance-dto";
import { ExchangeState } from "src/app/models/enums/exchange-state";
import { Currency, ExchangeCurrencyList } from "src/app/models/enums/currency";
import { CurrencySubTypes } from "src/app/models/enums/currency-sub-types";
import { CalculationType } from "src/app/models/enums/calculaton-type";
import { AlertService } from "src/app/services/alert.service";
import { CustomerCreateEditModalComponent } from "src/app/ui/components/customers/customer-create-edit-modal/customer-create-edit-modal.component";
import { ExchangeBalace } from "src/app/models/exchange/exchange-balance";
import {
  NgbActiveModal,
  NgbModal,
  NgbTooltip,
} from "@ng-bootstrap/ng-bootstrap";
import { CurrencyAmountPipe } from "src/app/shared/pipes/currency-amount.pipe";
import { BasePagingRequest } from "src/app/core/models/requests/base-paging-request";
import { ExchangeItem } from "src/app/models/exchange/exchange-item-request";
import { ExchangeFilter } from "src/app/models/filters/exchange-filter";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { ExchangeService } from "src/app/services/exchange.service";
import { AccountTransactionService } from "src/app/services/account-transaction.service";
import { NotificationService } from "src/app/services/notification.service";
import { CustomerService } from "src/app/services/customer.service";
import { LocalParityService } from "src/app/services/local-parity.service";
import { BasicParity } from "src/app/models/parity/basic-parity";
import { ApplicationUser } from "src/app/models/application-user/application-user";
import {
  getCurrencySubTypeItem,
  getMilyem,
} from "src/app/core/helpers/common-functions";

@Component({
  selector: "app-exchange-operation",
  templateUrl: "./exchange-operation.component.html",
  styleUrls: ["./exchange-operation.component.scss"],
  providers: [NgbActiveModal, CurrencyAmountPipe],
})
export class ExchangeOperationComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild("infoModal") infoModal;
  @ViewChild("case") caseSelectRef: ElementRef;
  @ViewChild("t1") tooltipRef: NgbTooltip;
  sellingCurrencyCostLoading: boolean = false;
  buyingCurrencyCostLoading: boolean = false;
  user: ApplicationUser = JSON.parse(localStorage.getItem("currentUser"));
  baseMilyem = 0;
  useSingleCase: boolean = true;
  isLastCalculationPayment: boolean = true;
  print = true;
  operationType: OperationType;
  customer: Customer;
  customerIdFromRoute: any;
  customer$: Observable<any>;
  customersLoading = false;
  customerInput$ = new Subject<string>();
  parityValue;
  currencyList = ExchangeCurrencyList;
  currentBranch: Branch = JSON.parse(localStorage.getItem("currentBranch"));
  branchId = this.currentBranch.id;
  ExchangeState = ExchangeState;
  breadCrumbItems: Array<{}> = [
    { label: "Alım-Satım" },
    { label: "İşlem", active: true },
  ];
  Currency = Currency;
  CurrencySubTypes = CurrencySubTypes;
  exchangeSubTypes: CurrencySubTypes[];
  paymentSubTypes: CurrencySubTypes[];
  branchBalance: BranchBalanceRequest = { branchId: this.branchId };
  balanceOfBranch: AccountBalanceDTO;
  lastOperationList: ExchangeItem[];
  customerList: SelectItem[];
  parityList: Parity[];
  uptodateParityList: Parity[];
  localParityList: Parity[] = JSON.parse(localStorage.getItem("parities"));
  newParityForLocal: Parity;
  form!: FormGroup;
  customerFilter: CustomerFilter = {};
  caseFilter: CaseFilter = {
    branchId: this.branchId,
    applicationUserId: this.user.id,
  };
  request: ExchangeItem = {};
  selectedParityObject: Parity = {
    sourceCurrency: this.request.exchangeCurrency,
    destinationCurrency: this.request.paymentCurrency,
    // calculationType: CalculationType.Divide,
  };
  summaryCart: ExchangeItem[] = [];
  tempItem: ExchangeItem;

  lastOperationsRequest: BasePagingRequest<ExchangeFilter> = {
    pageIndex: 1,
    itemPerPage: 10,
    filter: {},
  };
  useDescription: boolean = false;
  parityIconClass: string;
  parityTooltipText: string;
  parityFounded: boolean;
  paymentRate: number;
  totalAmount: number = 1;
  isLessThen1 = false;
  caseList: SelectItem[];
  operationSummary: ExchangeItem[] = [];
  infoCart: ExchangeItem[] = [];
  operationDate;
  defaultCaseId: number;
  summaryBalances: ExchangeBalace = { exchanges: [], payments: [] };
  infoBalances: ExchangeBalace = { exchanges: [], payments: [] };
  groupId: string;
  costForBuyingCurrency: ExchangeItem;
  costForSellingCurrency: ExchangeItem;
  isDisabled: boolean = false;
  constructor(
    private service: ExchangeService,
    private dataService: DataService,
    private accountTransactionService: AccountTransactionService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private router: Router,
    private localParity: LocalParityService
  ) {
    super();
  }
  async ngOnInit() {
    // this.signalRService.on(HubUrls.ParityHub, ReceiveFunctions.ParityAddedMessageReceiveFunction, message => {
    //   console.log('message from signalr', message);
    // });
    this.customerService.refreshIndex.subscribe((res) => {});
    this.setDefault();
    this.route.queryParamMap.subscribe((params) => {
      this.customerIdFromRoute = +params.get("customerId") || 0;
      this.request.customerId = this.customerIdFromRoute;
      this.request.exchangeCurrency =
        +params.get("exchangeCurrency") || Currency.Tl;
      this.request.paymentCurrency =
        +params.get("paymentCurrency") || Currency.Tl;
    });
    this.form = new FormGroup({
      amount: new FormControl("", [Validators.required]),
      rate: new FormControl("", [Validators.required]),
      parity: new FormControl("", [Validators.required]),
    });

    this.lastOperationsRequest.filter.branchId = this.branchId;
    this.customerFilter.branchId = this.branchId;

    this.parityList = (
      await this.dataService.getParity({ uptodate: false }).toPromise()
    ).data;
    this.uptodateParityList = (
      await this.dataService.getParity({ uptodate: true }).toPromise()
    ).data;

    // setInterval(async () =>  {
    // this.parityList = (await this.dataService.getParity({}).toPromise()).data;
    // }, 30000);

    await this.setParity();
    //await this.calculateExchangeAmount();
    this.service
      .getAll(this.lastOperationsRequest)
      .toPromise()
      .then((res) => (this.lastOperationList = res.data.Items));
    this.loadCases();
    this.loadCustomers();
    this.exchangeCurrencyChanged();
    // this.exchangeSubTypeChanged();
    this.paymentCurrencyChanged();
    // this.paymentSubTypeChanged();
  }

  loadCustomers() {
    if (this.request.customerId != null && this.request.customerId != 0) {
      this.loadCustomerBalance(this.request.customerId);
      this.dataService
        .getCustomer({ id: this.request.customerId, isActive: true })
        .toPromise()
        .then((res) => {
          this.customerList = res.data;
        });
    } else {
      this.customer$ = concat(
        of([]),
        this.customerInput$.pipe(
          filter((res) => {
            return res !== null;
          }),
          distinctUntilChanged(),
          debounceTime(200),
          tap(() => (this.customersLoading = true)),
          switchMap((term) => {
            return this.dataService
              .getCustomer({ name: term, isActive: true })
              .pipe()
              .toPromise()
              .then((res) => {
                this.customersLoading = false;
                return res.data;
              });
          })
        )
      );
    }
  }

  clearSelectedCustomer() {
    this.customerIdFromRoute = null;
    this.request.customerId = null;
    this.customer = null;
    this.summaryBalances = { exchanges: [], payments: [] };
    this.infoBalances = { exchanges: [], payments: [] };
    this.router.navigate([], {
      queryParams: {
        customerId: null,
        branchId: null,
      },
      queryParamsHandling: "merge",
    });
    this.loadCustomers();
  }

  loadCustomerBalance(customerId: number) {
    if (customerId != null) {
      this.customerService
        .findByBranch(customerId, this.branchId)
        .toPromise()
        .then((res) => (this.customer = res.data));
      this.customerService.refreshIndex.subscribe((res) => {
        this.customerService
          .findByBranch(customerId, this.branchId)
          .toPromise()
          .then((res) => (this.customer = res.data));
      });
    } else {
      this.clearSelectedCustomer();
    }
  }

  async exchangeCurrencyChanged() {
    this.setBaseMilyem();
    this.sellingCurrencyCostLoading = true;
    let item = getCurrencySubTypeItem(this.request.exchangeCurrency);
    this.exchangeSubTypes = item.subtypes;
    this.request.exchangeSubType = item.default;
    this.request.exchangeMilyem = item.milyem;
    this.costForSellingCurrency = await this.getCost(
      this.request.exchangeCurrency,
      this.request.exchangeSubType
    );
    this.sellingCurrencyCostLoading = false;
    await this.setParity();
    this.calculateAmount();
  }

  async exchangeSubTypeChanged() {
    this.request.exchangeMilyem = getMilyem(this.request.exchangeSubType);
    await this.setParity();
    this.costForSellingCurrency = await this.getCost(
      this.request.exchangeCurrency,
      this.request.exchangeSubType
    );
    this.sellingCurrencyCostLoading = false;
    this.calculateAmount();
  }

  async paymentCurrencyChanged() {
    this.buyingCurrencyCostLoading = true;
    this.setBaseMilyem();
    let item = getCurrencySubTypeItem(this.request.paymentCurrency);
    this.paymentSubTypes = item.subtypes;
    this.request.paymentSubType = item.default;
    this.request.paymentMilyem = item.milyem;
    await this.setParity();
    this.costForBuyingCurrency = await this.getCost(
      this.request.paymentCurrency,
      this.request.paymentSubType
    );
    this.buyingCurrencyCostLoading = false;
  }

  async paymentSubTypeChanged() {
    this.request.paymentMilyem = getMilyem(this.request.paymentSubType);
    await this.setParity();
    this.costForBuyingCurrency = await this.getCost(
      this.request.paymentCurrency,
      this.request.paymentSubType
    );
    this.buyingCurrencyCostLoading = false;
    this.calculateAmount();
  }

  parityHelper: number = 1;
  async setParity() {
    const FORM_PARITY: Parity = {
      sourceCurrency:
        this.request.paymentCurrency != null
          ? Number(this.request.paymentCurrency)
          : this.request.paymentCurrency,
      sourceSubType:
        this.request.paymentSubType != null
          ? Number(this.request.paymentSubType)
          : this.request.paymentSubType,
      destinationCurrency:
        this.request.exchangeCurrency != null
          ? Number(this.request.exchangeCurrency)
          : this.request.exchangeCurrency,
      destinationSubType:
        this.request.exchangeSubType != null
          ? Number(this.request.exchangeSubType)
          : this.request.exchangeSubType,
    };

    // firstly look at the manuel added parityList comes from server
    this.selectedParityObject = this.parityList.find(
      (item) =>
        item.sourceCurrency == FORM_PARITY.sourceCurrency &&
        item.destinationCurrency == FORM_PARITY.destinationCurrency &&
        item.sourceSubType == FORM_PARITY.sourceSubType &&
        item.destinationSubType == FORM_PARITY.destinationSubType
    );

    if (this.selectedParityObject) {
      this.parityIconClass = "fas fa-hand-paper px-2 text-secondary";
      this.parityTooltipText = "Manuel Kur";
    }

    // secondly look at the uptodate parityList comes from server
    if (!this.selectedParityObject) {
      this.selectedParityObject = this.uptodateParityList.find(
        (item) =>
          item.sourceCurrency == FORM_PARITY.sourceCurrency &&
          item.destinationCurrency == FORM_PARITY.destinationCurrency &&
          item.sourceSubType == FORM_PARITY.sourceSubType &&
          item.destinationSubType == FORM_PARITY.destinationSubType
      );
      if (this.selectedParityObject) {
        this.parityIconClass = "fas fa-dot-circle px-2 text-secondary";
        this.parityTooltipText = "Güncel Kur";
      }
    }

    // after than look at the localStorage for parity
    let parityFoundedOnLocal: Boolean = false;
    if (!this.selectedParityObject) {
      parityFoundedOnLocal = this.localParity.hasValue(FORM_PARITY);
      this.selectedParityObject = parityFoundedOnLocal
        ? this.localParity.find(FORM_PARITY, true)
        : null;
    }
    if (parityFoundedOnLocal) {
      this.parityIconClass = "fas fa-undo-alt px-2 text-secondary";
      this.parityTooltipText = "Son İşlem";
    }
    if (!this.selectedParityObject) {
      if (this.request.paymentCurrency == this.request.exchangeCurrency) {
        this.selectedParityObject = {
          destinationCurrency: this.request.paymentCurrency,
          destinationSubType: this.request.paymentSubType,
          sourceCurrency: this.request.exchangeCurrency,
          sourceSubType: this.request.exchangeSubType,
          calculationType: CalculationType.Multiple,
          value: 1,
        };
        this.parityTooltipText = "Pariteler eşit";
        this.parityIconClass = "fas fa-equals px-2 text-secondary";
      } else {
        this.parityIconClass = "fas fa-windows-minimize px-2 text-danger";
        this.parityTooltipText = "Bulunamadı.";
        this.request.calculationType = null;
      }
    }

    if (this.selectedParityObject) {
      this.request.instantParity = this.selectedParityObject.value;
      this.parityHelper = this.selectedParityObject.value;
      this.parityValue = this.selectedParityObject.value;
      this.request.calculationType = this.selectedParityObject.calculationType;
      this.parityFounded = true;
    } else {
      this.request.instantParity = null;
      this.parityHelper = null;
      this.parityValue = null;
      this.parityFounded = false;
    }
  }

  async setNewParitiesToLocal(items: ExchangeItem[]) {
    items.forEach((p) => {
      let parity: Parity = {
        destinationCurrency: p.exchangeCurrency,
        destinationSubType: p.exchangeSubType,
        sourceCurrency: p.paymentCurrency,
        sourceSubType: p.paymentSubType,
        calculationType: p.calculationType,
        value: p.parity,
      };

      if (this.localParity.hasValue(parity)) {
        this.localParity.update(parity);
      } else {
        this.localParity.create(parity);
      }
    });
  }

  async calculateAmount() {
    if (!this.request.exchangeAmount && this.request.paymentAmount)
      this.isLastCalculationPayment = false;
    if (this.request.exchangeAmount && !this.request.paymentAmount)
      this.isLastCalculationPayment = true;

    this.isLastCalculationPayment
      ? this.calculatePaymentAmount()
      : this.calculateExchangeAmount();
  }
  setBaseMilyem() {
    this.baseMilyem = 0;
    if (
      this.request.exchangeCurrency == Currency.Gold ||
      this.request.paymentCurrency == Currency.Gold
    ) {
      if (
        this.request.exchangeCurrency == Currency.Usd ||
        this.request.exchangeCurrency == Currency.Euro ||
        this.request.paymentCurrency == Currency.Usd ||
        this.request.paymentCurrency == Currency.Euro
      )
        this.baseMilyem = 995;
      else this.baseMilyem = 1000;
    }
  }

  async getCost(currency: Currency, subType?: CurrencySubTypes) {
    currency = Number(currency);
    if (subType != undefined || subType != undefined) subType = Number(subType);
    let request: BasicParity = {
      branchId: this.currentBranch.id,
      paymentCurrency: currency,
      paymentSubType: subType,
    };

    return (await this.service.getCosts(request).toPromise()).data;
  }

  // set parity with has been entered amount values
  calculateParity() {
    if (
      this.request.exchangeAmount != null &&
      this.request.paymentAmount != null
    ) {
      let inputMilyemFactor =
        this.request.exchangeCurrency == Currency.Gold
          ? this.request.exchangeMilyem / this.baseMilyem
          : 1;
      let outputMilyemFactor =
        this.request.paymentCurrency == Currency.Gold
          ? this.request.paymentMilyem / this.baseMilyem
          : 1;
      let factor = inputMilyemFactor / outputMilyemFactor;
      this.request.parity =
        this.request.paymentAmount / this.request.exchangeAmount;
      if (this.request.parity < 1) {
        this.request.parity =
          this.request.exchangeAmount / this.request.paymentAmount;
      }
      this.parityHelper = this.request.parity;

      this.request.parity *= factor;
      this.request.parity = Number.parseFloat(this.request.parity.toFixed(4));
    }
  }
  async calculatePaymentAmount() {
    if (!this.request.exchangeAmount) return;
    if (this.request.parity == null) return this.calculateParity();

    let calculationType = this.selectedParityObject?.calculationType;

    if (calculationType != null) {
      switch (calculationType) {
        case CalculationType.Multiple:
          this.request.paymentAmount =
            this.request.exchangeAmount * this.request.parity;
          break;
        case CalculationType.Divide:
          this.request.paymentAmount =
            this.request.exchangeAmount / this.request.parity;
          break;
      }

      let inputMilyemFactor =
        this.request.exchangeCurrency == Currency.Gold
          ? this.request.exchangeMilyem / this.baseMilyem
          : 1;
      let outputMilyemFactor =
        this.request.paymentCurrency == Currency.Gold
          ? this.request.paymentMilyem / this.baseMilyem
          : 1;
      let factor = inputMilyemFactor / outputMilyemFactor;
      this.request.paymentAmount *= factor;
      this.request.paymentAmount = Number.parseFloat(
        this.request.paymentAmount.toFixed(4)
      );
      this.isLastCalculationPayment = true;
    } else {
      this.openTooltip();
    }
  }

  openTooltip() {
    this.tooltipRef.open();
  }

  async calculateExchangeAmount() {
    if (!this.request.paymentAmount) return;
    if (this.request.parity == null) return this.calculateParity();

    let calculationType = this.selectedParityObject?.calculationType;
    if (calculationType != null) {
      switch (calculationType) {
        case CalculationType.Multiple:
          this.request.exchangeAmount =
            this.request.paymentAmount / this.request.parity;
          break;
        case CalculationType.Divide:
          this.request.exchangeAmount =
            this.request.paymentAmount * this.request.parity;
          break;
      }
      let inputMilyemFactor =
        this.request.exchangeCurrency == Currency.Gold
          ? this.request.exchangeMilyem / this.baseMilyem
          : 1;
      let outputMilyemFactor =
        this.request.paymentCurrency == Currency.Gold
          ? this.request.paymentMilyem / this.baseMilyem
          : 1;
      let factor = outputMilyemFactor / inputMilyemFactor;
      this.request.exchangeAmount *= factor;
      this.request.exchangeAmount = Number.parseFloat(
        this.request.exchangeAmount.toFixed(4)
      );

      this.isLastCalculationPayment = false;
    } else {
      this.openTooltip();
    }
  }

  validation(): boolean {
    if (!this.request.customerId) {
      this.alertService.showErrorToast("Müşteri seçiniz!");
      return false;
    }

    if (!this.request.exchangeAmount) {
      this.alertService.showErrorToast("Satış miktarı boş olmamalı.");
      return false;
    }

    if (!this.request.paymentAmount) {
      this.alertService.showErrorToast("Alış miktarı boş olmamalı.");
      return false;
    }

    if (!this.request.parity) {
      this.alertService.showErrorToast("Kur boş olmamalı.");
      return false;
    }
    return true;
  }

  // validateParity(): boolean{
  //   let percentage = parseFloat(Number((this.parityHelper * 4) / 100).toFixed(2));
  //   let maxParity = parseFloat(Number(this.parityHelper + percentage).toFixed(2));
  //   let minParity = parseFloat(Number(this.parityHelper - percentage).toFixed(2));
  //   let greaterThanBase = this.request.parity > maxParity;
  //   let lessThanBase = this.request.parity < minParity;
  //   if (!this.request.hasNewParity) {
  //     if (greaterThanBase || lessThanBase){
  //       return false;
  //     }
  //     else{
  //       return true;
  //     }
  //   }
  // }

  addToCart() {
    if (this.validation()) {
      this.request.customerName = this.customer.name;
      this.tempItem = {};
      if (this.request.instantParity == null)
        this.request.instantParity = Number(this.request.parity.toString());
      this.tempItem = JSON.parse(JSON.stringify(this.request));
      this.tempItem.customerId = Number(this.tempItem.customerId);
      this.tempItem.exchangeCurrency = Number(this.tempItem.exchangeCurrency);
      this.tempItem.paymentCurrency = Number(this.tempItem.paymentCurrency);
      this.tempItem.exchangeCaseId = Number(this.tempItem.exchangeCaseId);
      this.tempItem.calculationType = this.selectedParityObject.calculationType;

      if (this.tempItem.exchangeSubType)
        this.tempItem.exchangeSubType = Number(this.tempItem.exchangeSubType);

      if (this.tempItem.paymentSubType)
        this.tempItem.paymentSubType = Number(this.tempItem.paymentSubType);

      if (this.useSingleCase) {
        this.tempItem.exchangeCaseName = this.getCaseName(
          this.tempItem.exchangeCaseId
        );
        this.tempItem.paymentCaseId = this.tempItem.exchangeCaseId;
        this.tempItem.paymentCaseName = this.tempItem.exchangeCaseName;
      }

      if (!this.useSingleCase) {
        this.tempItem.exchangeCaseName = this.getCaseName(
          this.tempItem.exchangeCaseId
        );
        this.tempItem.paymentCaseName = this.getCaseName(
          this.tempItem.paymentCaseId
        );
      }
      this.summaryCart.push(this.tempItem);
      this.calculateExchangeBalances(this.tempItem, false);
      this.infoBalances = JSON.parse(JSON.stringify(this.summaryBalances));
      this.setDefault();
    }
  }

  removeFromCart(item: ExchangeItem) {
    this.calculateExchangeBalances(item, true);
    this.infoBalances = JSON.parse(JSON.stringify(this.summaryBalances));
    this.summaryCart = this.summaryCart.filter((p) => p !== item);
  }

  submit(state: ExchangeState, addNewItem: boolean = false) {
    var isValid = addNewItem ? this.validation() : true;
    if (addNewItem && isValid) {
      if (this.request.instantParity == null)
        this.request.instantParity = Number(this.request.parity.toString());
      this.request.calculationType = this.selectedParityObject.calculationType;
      this.summaryCart.push(JSON.parse(JSON.stringify(this.request)));
      this.summaryCart[0].customerName = this.customer.name;
      this.summaryCart[0].exchangeCaseName = this.getCaseName(
        this.summaryCart[0].exchangeCaseId
      );
      this.summaryCart[0].paymentCaseName = this.getCaseName(
        this.summaryCart[0].paymentCaseId
      );
    }

    if (isValid) {
      this.request.state = state;
      if (this.summaryCart.length > 0) {
        this.summaryCart.forEach((item) => {
          item.state = state;
          item.forceToAction = false;
        });
      }
      this.infoCart = JSON.parse(JSON.stringify(this.summaryCart));
      // this.infoCart.forEach((item) => {
      //   item.exchangeCurrency = parseInt(String(item.exchangeCurrency));
      //   item.paymentCurrency = parseInt(String(item.paymentCurrency));
      //   item.exchangeMilyem = parseInt(String(item.exchangeMilyem));
      //   item.exchangeSubType = parseInt(String(item.exchangeSubType));
      //   item.paymentSubType = parseInt(String(item.paymentSubType));
      //   item.paymentMilyem = parseInt(String(item.paymentMilyem));
      // });
      this.createMultiple(this.summaryCart);
    }
  }
  createMultiple(items: ExchangeItem[], forceToAction: boolean = false) {
    items.forEach((item) => {
      item.exchangeCurrency = parseInt(item.exchangeCurrency.toString());
      item.paymentCurrency = parseInt(item.paymentCurrency.toString());
      if (item.exchangeSubType)
        item.exchangeSubType = parseInt(item.exchangeSubType.toString());
      if (item.paymentSubType)
        item.paymentSubType = parseInt(item.paymentSubType.toString());
    });

    this.isDisabled = true;
    this.service
      .createMultiple({ items: items, forceToAction: forceToAction })
      .toPromise()
      .catch((res) => {
        if (res.error.isNeedConfirm) {
          var message = res.error.errorMessage;
          var html = "<ul class='list-group'>";
          for (var k in message) {
            if (message.hasOwnProperty(k)) {
              if (k) {
                let messageArray = message[k].split("\r\n");
                messageArray.forEach((element) => {
                  if (element)
                    html +=
                      "<li class='list-group-item text-danger'>" +
                      k +
                      ": " +
                      element +
                      "</li>";
                });
              }
            }
          }
          html += "</ul>";
          this.alertService
            .showConfirmAlert(html, 'Devam Et', 'Kuru Düzelt', true)
            .then((result) => {
              if (result.isConfirmed) {
                this.isDisabled = true;
                this.createMultiple(items, true);
              } else {
                this.isDisabled = false;
              }
            })
            .catch((err) => (this.isDisabled = false));
        }
      })
      .then((res) => {
        this.isDisabled = false;
        if (!res) {
        } else if (res.isSuccess) {
          this.alertService.showSuccessToast("İşlem başarılı!");
          this.groupId = res.data.groupId;
          this.accountTransactionService.refreshIndex.next();
          this.loadCustomerBalance(this.customer.id);
          this.summaryCart = [];
          this.summaryBalances.exchanges = [];
          this.summaryBalances.payments = [];
          this.setNewParitiesToLocal(this.infoCart);
          this.setDefault();
          this.service
            .getAll(this.lastOperationsRequest)
            .toPromise()
            .then((res) => (this.lastOperationList = res.data.Items));
          this.openModal(this.infoModal);
          if (this.print)
            this.goToLink("/receipts/exchange-summary/" + this.groupId);
        }
      })
      .catch((err) => (this.isDisabled = false));
  }

  donePayment(item: ExchangeItem) {
    this.service.paymentsDone(item.id).subscribe((res) => {
      this.notificationService.showSuccess();
      this.service
        .getAll(this.lastOperationsRequest)
        .toPromise()
        .then((res) => (this.lastOperationList = res.data.Items));
    });
  }

  doneWithDebt(item: ExchangeItem) {
    this.service.doneWithDebt(item.id).subscribe((res) => {
      this.notificationService.showSuccess();
      this.service
        .getAll(this.lastOperationsRequest)
        .toPromise()
        .then((res) => (this.lastOperationList = res.data.Items));
    });
  }

  doneWithDeposit(item: ExchangeItem) {
    this.service.doneWithDeposit(item.id).subscribe((res) => {
      this.notificationService.showSuccess();
      this.service
        .getAll(this.lastOperationsRequest)
        .toPromise()
        .then((res) => (this.lastOperationList = res.data.Items));
    });
  }

  delete(id: number) {
    this.alertService.showDeleteAlert().then((result) => {
      if (result.isConfirmed) {
        this.service
          .delete(id)
          .toPromise()
          .then((res) => {
            this.alertService.showSuccess();
            this.service
              .getAll(this.lastOperationsRequest)
              .toPromise()
              .then((res) => (this.lastOperationList = res.data.Items));
          });
      }
    });
  }

  loadCases() {
    this.dataService
      .getCase(this.caseFilter)
      .toPromise()
      .then((res) => {
        this.caseList = res.data;
        if (!this.defaultCaseId) {
          let defaultCase = res.data.find((p) => p.isDefault == true);
          this.defaultCaseId = defaultCase
            ? defaultCase.value
            : this.caseList[0].value;
          this.request.paymentCaseId = this.defaultCaseId;
          this.request.exchangeCaseId = this.defaultCaseId;
        }
      });
  }

  openCreateCustomer() {
    this.modalService.open(CustomerCreateEditModalComponent);
  }

  openModal(modal) {
    this.operationDate = new Date();
    this.modalService
      .open(modal, {
        ariaLabelledBy: "modal-basic-title",
        centered: true,
        size: "lg",
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
  }

  closeAllModals() {
    this.modalService.dismissAll();
  }

  setMultipleCase() {
    if (this.useSingleCase) {
      this.request.paymentCaseId = this.request.exchangeCaseId;
    }
  }

  getCaseName(id: number): string {
    return this.caseList.find((p) => p.value == id).text;
  }

  calculateExchangeBalances(item: ExchangeItem, isRemove: boolean) {
    if (this.summaryBalances.exchanges.length > 0) {
      let foundedItem = this.summaryBalances.exchanges.find(
        (p) =>
          p.currency == item.exchangeCurrency &&
          p.subType == item.exchangeSubType
      );
      if (foundedItem) {
        isRemove
          ? (foundedItem.balance -= item.exchangeAmount)
          : (foundedItem.balance += item.exchangeAmount);
      } else {
        this.summaryBalances.exchanges.push({
          currency: item.exchangeCurrency,
          subType: item.exchangeSubType,
          milyem: item.exchangeMilyem,
          balance: item.exchangeAmount,
        });
      }
    }
    if (this.summaryBalances.exchanges.length == 0) {
      this.summaryBalances.exchanges.push({
        currency: item.exchangeCurrency,
        subType: item.exchangeSubType,
        milyem: item.exchangeMilyem,
        balance: item.exchangeAmount,
      });
    }

    if (this.summaryBalances.payments.length > 0) {
      let foundedItem = this.summaryBalances.payments.find(
        (p) =>
          p.currency == item.paymentCurrency && p.subType == item.paymentSubType
      );
      if (foundedItem) {
        isRemove
          ? (foundedItem.balance -= item.paymentAmount)
          : (foundedItem.balance += item.paymentAmount);
      } else {
        this.summaryBalances.payments.push({
          currency: item.paymentCurrency,
          subType: item.paymentSubType,
          milyem: item.paymentMilyem,
          balance: item.paymentAmount,
        });
      }
    }
    if (this.summaryBalances.payments.length == 0) {
      this.summaryBalances.payments.push({
        currency: item.paymentCurrency,
        subType: item.paymentSubType,
        milyem: item.paymentMilyem,
        balance: item.paymentAmount,
      });
    }

    this.summaryBalances.exchanges = this.summaryBalances.exchanges.filter(
      (p) => p.balance != 0
    );
    this.summaryBalances.payments = this.summaryBalances.payments.filter(
      (p) => p.balance != 0
    );
  }

  setDefault() {
    this.request.paymentCurrency = Currency.Tl;
    this.request.paymentSubType = null;
    this.request.paymentMilyem = null;
    this.request.parity = null;
    this.useDescription = false;
    this.request.description = null;
    this.request.exchangeCurrency = Currency.Tl;
    this.request.exchangeSubType = null;
    this.exchangeSubTypes = null;
    this.paymentSubTypes = null;
    this.request.exchangeMilyem = null;
    this.request.exchangeAmount = null;
    this.request.paymentAmount = null;
    this.request.exchangeCaseId = this.defaultCaseId;
    this.request.paymentCaseId = this.request.exchangeCaseId;
    this.request.hasNewParity = false;
    this.useSingleCase = true;
    this.parityHelper = 1;
    this.costForBuyingCurrency = null;
    this.costForSellingCurrency = null;
    this.selectedParityObject = {
      sourceCurrency: this.request.exchangeCurrency,
      destinationCurrency: this.request.paymentCurrency,
      calculationType: CalculationType.Divide,
    };
    this.parityIconClass = null;
    this.parityTooltipText = null;
  }

  transposeToOperation() {
    let currency = this.request.exchangeCurrency;
    let subType = this.request.exchangeSubType;
    let milyem = this.request.exchangeMilyem;
    let subTypes = this.exchangeSubTypes;
    let sellingCosts = this.costForSellingCurrency;

    this.request.exchangeCurrency = this.request.paymentCurrency;
    this.request.exchangeSubType = this.request.paymentSubType;
    this.request.exchangeMilyem = this.request.paymentMilyem;
    this.request.exchangeAmount = null;
    this.exchangeSubTypes = this.paymentSubTypes;

    this.request.paymentCurrency = currency;
    this.request.paymentSubType = subType;
    this.request.paymentMilyem = milyem;
    this.request.paymentAmount = null;
    this.paymentSubTypes = subTypes;

    this.costForSellingCurrency = this.costForBuyingCurrency;
    this.costForBuyingCurrency = sellingCosts;

    this.setBaseMilyem();

    this.setParity();
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  changeCalculateType() {
    if (!this.selectedParityObject) {
      this.request.hasNewParity = true;
      this.selectedParityObject = {
        sourceCurrency: this.request.exchangeCurrency,
        sourceSubType: this.request.exchangeSubType,
        destinationCurrency: this.request.paymentCurrency,
        destinationSubType: this.request.paymentSubType,
        calculationType: null,
      };
    }

    this.parityFounded = false;
    this.parityTooltipText = "Bulunamadı.";
    const type = this.selectedParityObject.calculationType;
    this.selectedParityObject.calculationType =
      type == CalculationType.Divide
        ? CalculationType.Multiple
        : CalculationType.Divide;
  }
}
