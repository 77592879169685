import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerImportComponent } from './customer-import.component';



@NgModule({
  declarations: [
    CustomerImportComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CustomerImportComponent
  ]
})
export class CustomerImportModule { }
