import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductImportComponent } from './product-import.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { UploadModule } from '../../uploads/upload/upload.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ComponentsModule as SharedComponentsModule } from 'src/app/shared/components/components.module';




@NgModule({
  declarations: [
    ProductImportComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    DirectivesModule,
    FormsModule,
    PipesModule,
    NgbDropdownModule,
    UploadModule,
    NgSelectModule,
  ],
  exports: [
    ProductImportComponent
  ]
})
export class ProductImportModule { }
