import { Pipe, PipeTransform } from "@angular/core";
import { Currency } from "src/app/models/enums/currency";

@Pipe({
  name: "customCurrency",
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(currency: any, ...args: number[]): string {
    var text = "";
    let value: Currency;
    if (typeof currency == "string") value = Currency[currency];
    else if (typeof currency == "number")
      value = Number(String(currency)) as Currency;
    else value = currency as Currency;
    switch (value) {
      case Currency.Tl:
        text = "TL";
        break;
      case Currency.Usd:
        text = "USD";
        break;
      case Currency.Euro:
        text = "EURO";
        break;
      case Currency.Gold:
        text = "Altın";
        break;
      case Currency.Silver:
        text = "Gümüş";
        break;
      case Currency.Accessories:
        text = "Sarrafiye";
        break;
      case Currency.OtherAccessories:
        text = "Sarrafiye(Diğer)";
        break;
      case Currency.Other:
        text = "Diğer";
        break;
    }
    return text;
  }
}
