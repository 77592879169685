import { Component, OnInit } from '@angular/core';
import { Branch } from 'src/app/models/branch/branch';
import { ManufactureOrderState } from 'src/app/models/enums/manufacture-order-state';
import { ManufactureOrderFilter } from 'src/app/models/filters/manufacture-order-filter';

@Component({
  selector: 'app-manufacture-orders-tab-widget',
  templateUrl: './manufacture-orders-tab-widget.component.html',
  styleUrls: ['./manufacture-orders-tab-widget.component.scss']
})
export class ManufactureOrdersTabWidgetComponent implements OnInit {
  upcomingFilter: ManufactureOrderFilter = {
    sortBy: 'deliveryDate',
    term: 'upcoming',
    sortAsc: true
  }
  inProgressFilter: ManufactureOrderFilter = {
    state: ManufactureOrderState.InProgress,
    sortBy: 'deliveryDate',
    term: 'upcoming',
    sortAsc: false
  }
  waitForDeliverFilter: ManufactureOrderFilter = {
    state: ManufactureOrderState.WaitForDeliver,
    sortBy: 'deliveryDate',
    term: 'upcoming',
    sortAsc: false
  }
  missesFilter: ManufactureOrderFilter = {
    sortBy: 'deliveryDate',
    term: 'misses',
    sortAsc: false
  }
  constructor() { }

  ngOnInit(): void {
  }

}
