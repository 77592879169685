import { Pipe, PipeTransform } from "@angular/core";
import { Currency } from "src/app/models/enums/currency";
import { CurrencySubTypes } from "src/app/models/enums/currency-sub-types";

@Pipe({
  name: "priceCurrency",
})
export class PriceCurrencyPipe implements PipeTransform {
  transform(currency: Currency, subType: CurrencySubTypes): string {
    if (currency != null) {
      switch (currency) {
        case Currency.Tl:
          return "TL";
        case Currency.Usd:
          return "USD";
        case Currency.Euro:
          return "EURO";
        case Currency.Gold:
          switch (subType) {
            case CurrencySubTypes.Has:
              return "Has";
            case CurrencySubTypes.Carat22:
              return "22 Ayar";
            case CurrencySubTypes.Carat21:
              return "21 Ayar";
            case CurrencySubTypes.Carat20:
              return "20 Ayar";
            case CurrencySubTypes.Carat18:
              return "18 Ayar";
            case CurrencySubTypes.Carat14:
              return "14 Ayar";
            case CurrencySubTypes.Carat8:
              return "8 Ayar";
          }
        case Currency.Silver:
          switch (subType) {
            case CurrencySubTypes.Granular:
              return "Granül (Gümüş)";
            case CurrencySubTypes.Ingot:
              return "Külçe (Gümüş)";
          }
        case Currency.Accessories:
          switch (subType) {
            case CurrencySubTypes.Gram24:
              return "Gram(24)";
            case CurrencySubTypes.Gram22:
              return "Gram(22)";
            case CurrencySubTypes.YeniCeyrek:
              return "Yeni Çeyrek";
            case CurrencySubTypes.YeniYarim:
              return "Yeni Yarım";
            case CurrencySubTypes.YeniTam:
              return "Yeni Ziynet";
            case CurrencySubTypes.YeniAta:
              return "Yeni Ata Lira";
            case CurrencySubTypes.YeniGremse:
              return "Yeni Gremse";
            case CurrencySubTypes.Resat:
              return "Reşat";
            case CurrencySubTypes.Resat5:
              return "Reşat 5";
            case CurrencySubTypes.EskiCeyrek:
              return "Eski Çeyrek";
            case CurrencySubTypes.EskiYarim:
              return "Eski Yarım";
            case CurrencySubTypes.EskiAta:
              return "Eski Ata Lira";
            case CurrencySubTypes.EskiTam:
              return "Eski Ziynet";
            case CurrencySubTypes.EskiGremse:
              return "Eski Gremse";
          }
        case Currency.OtherAccessories:
          switch (subType) {
            case CurrencySubTypes.YeniAta5:
              return "Yeni Ata 5";
            case CurrencySubTypes.AtaGremse:
              return "Ata 2,5";
            case CurrencySubTypes.AtaYarim:
              return "Ata Yarım";
            case CurrencySubTypes.AtaCeyrek:
              return "Ata Çeyrek";
            case CurrencySubTypes.ResatGremse:
              return "Reşat 2,5";
            case CurrencySubTypes.ResatYarim:
              return "Reşat Yarım";
            case CurrencySubTypes.ResatCeyrek:
              return "Reşat Çeyrek";
            case CurrencySubTypes.Ziynet5:
              return "Ziynet 5";
            case CurrencySubTypes.EskiAta5:
              return "Eski Ata 5";
            case CurrencySubTypes.Aziz:
              return "Aziz";
            case CurrencySubTypes.HamitGremse:
              return "Hamit 2.5";
            case CurrencySubTypes.Hamit5:
              return "Hamit 5";
            case CurrencySubTypes.Adli:
              return "Adli";
            case CurrencySubTypes.Hamit:
              return "Hamit";
            case CurrencySubTypes.EskiPara:
              return "Eski Para";
            case CurrencySubTypes.Bilezik14:
              return "14 Ayar Bilezik";
            case CurrencySubTypes.Bilezik18:
              return "18 Ayar Bilezik";
            case CurrencySubTypes.Bilezik22:
              return "22 Ayar Bilezik";
            case CurrencySubTypes.Armali5li:
              return "Armalı 5'li";
          }
        case Currency.Other:
          switch (subType) {
            case CurrencySubTypes.JPY:
              return "Japon Yeni";
            case CurrencySubTypes.GBP:
              return "İngiliz Sterlini";
            case CurrencySubTypes.DKK:
              return "Danimarka Kronu";
            case CurrencySubTypes.SEK:
              return "İsveç Kronu";
            case CurrencySubTypes.NOK:
              return "Norveç Kronu";
            case CurrencySubTypes.CHF:
              return "İsviçre Frangı";
            case CurrencySubTypes.AUD:
              return "Avustralya Doları";
            case CurrencySubTypes.CAD:
              return "Kanada Doları";
            case CurrencySubTypes.SAR:
              return "Saudi Riyali";
            case CurrencySubTypes.RUB:
              return "Rus Rublesi";
            case CurrencySubTypes.BGN:
              return "Bulgar Levası";
            case CurrencySubTypes.AED:
              return "BAE Dirhemi";
            case CurrencySubTypes.QAR:
              return "Katar Riyali";
            case CurrencySubTypes.CNY:
              return "Çin Yuanı";
            case CurrencySubTypes.AZN:
              return "Azerbaycan Manatı";
            case CurrencySubTypes.KWD:
              return "Kuveyt Dinarı";
            case CurrencySubTypes.ILS:
              return "Yeni İsrail Şekeli";
            case CurrencySubTypes.MAD:
              return "Fas Dirhemi";
            case CurrencySubTypes.JOD:
              return "Ürdün Dinarı";
            case CurrencySubTypes.OMR:
              return "Umman Riyali";
            case CurrencySubTypes.XPT:
              return "Platin";
            case CurrencySubTypes.XPD:
              return "Petro Dolar";
            case CurrencySubTypes.Platin:
              return "Platin Gram";
            case CurrencySubTypes.Palladium:
              return "Paladyum Gram";
            case CurrencySubTypes.GEL:
              return "Gürcü Larisi";
          }
        default:
          switch (subType) {
            case CurrencySubTypes.Ceyrek:
              return "Çeyrek";
            case CurrencySubTypes.Yarim:
              return "Yarım";
            case CurrencySubTypes.Tam:
              return "Ziynet";
            case CurrencySubTypes.Ata:
              return "Ata Lira";
            case CurrencySubTypes.Ata5:
              return "Ata 5";
            case CurrencySubTypes.Gremse:
              return "Gremse";
            default:
              return "";
          }
      }
    }
  }
}
