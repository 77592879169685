<app-page-title title="Kasa Bakiyeleri" [breadcrumbItems]="breadCrumbItems"></app-page-title>
<app-loader></app-loader>

  <ng-container *ngIf="caseList.length > 0">
  <ul ngbNav #nav1="ngbNav" [(activeId)]="activeCase" class="nav-pills" (navChange)="caseChanged($event)">
    <li [ngbNavItem]="case.id" *ngFor="let case of caseList">
      <a ngbNavLink>{{case.name}}</a>
      <ng-template ngbNavContent>
        <div class="d-flex justify-content-end" *hasRole="[Role.Admin, Role.SuperAdmin]">
          <button class="btn btn-sm btn-danger mb-2" (click)="deleteCase()"> <i class="fas fa-trash-alt"></i> Kasayı sil</button>
          <button class="btn btn-sm btn-primary mb-2 ms-1" (click)="transferCase()"> <i class="fas fa-share-square"></i> Kasa Transferi</button>
        </div>
        <div>
          <ul ngbNav #nav2="ngbNav" [(activeId)]="activeAccount" class="nav-pill nav-fill mt-2">
            <li [ngbNavItem]="balance.currency" *ngFor="let balance of branchBalanceList">
              <a class="fs-5 custom-align p-1" ngbNavLink>
                <div class="d-flex justify-content-end position-absolute top-0 start-100 translate-middle" style="z-index:999">
                  <div triggers="hover" ngbTooltip="Hareket Ekle" container="body" class="transaction-add">
                    <span  (click)="openAccountTransactionAddModal(balance.currency, null)" > <i class="fas fa-plus"></i></span>
                </div>
                </div>
                <div class="card mini-stats-wid mb-0">
                  <div class="card-body" (click)="loadTransactionsByCurrency(balance.currency)">
                      <div class="media">
                        <div class="align-self-center" [innerHtml]="balance.currency | currencyIcon"></div>
                          <div class="media-body">
                              <p class="text-muted mb-2">
                                {{ balance.currency | customCurrency }}
                              </p>
                              <h5 class="mb-0"> {{ balance.balance | currencyAmount: balance.currency }} {{balance.currency | currencyWeight: balance.subType}}</h5>
                          </div>
                      </div>
                  </div>
                </div>
              </a>
              <ng-template ngbNavContent>
                <div class="row mt-2">
                  <div class="col mt-2" >
                    <app-account-transaction-list [showAsPartial]="true" [filter]="caseTransactionFilter" [showFilterAreas]="true" [filterAreas]="filterAreas" [showHeader]="true" ></app-account-transaction-list>
                  </div>
                  <div class="col-md-4" *ngIf="balance.subTypeBalances && balance.subTypeBalances.length > 0">
                    <ng-container *ngIf="balance.oldNewSubTypeBalances.length > 0 ">
                        <div class="card card-subtypes mt-2 mb-0">
                      <div class="table-responsive">
                        <table class="table mb-0">
                          <thead>
                          <tr class="bg-light text-primary">
                            <th style="width: 30%;">Eski - Yeni  Toplamı</th>
                            <th class="text-end"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let mergedBalance of balance.oldNewSubTypeBalances">
                            <td class="fs-6 px-3">{{ mergedBalance.subType | subTypeDisplay}}</td>
                              <td class="fs-6 text-end">{{ mergedBalance.balance }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                        </div>
                      </ng-container>
                      <div class="card card-subtypes mt-2 mb-0">
                      <div class="table-responsive ">
                    <table class="table table-striped mb-0 caption-top">
                      <thead>
                        <tr>
                          <th style="width: 30%;">Alt Birim</th>
                          <th class="text-end" *ngIf="!showScaleBalance">Miktar</th>
                          <th class="text-end" *ngIf="showScaleBalance">Has Miktar</th>
                          <th class="text-end" *ngIf="showScaleBalance">Terazi Miktar</th>
                        </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let subTypeBalance of balance.subTypeBalances">
                        <td class="fs-6 px-3">{{subTypeBalance.subType | subTypeDisplay }}</td>
                        <td class="fs-6 text-end">{{subTypeBalance.balance | currencyAmount: balance.currency}} {{balance.currency | currencyWeight: subTypeBalance.subType }} </td>
                        <td *ngIf="showScaleBalance" class="fs-6 text-end">{{subTypeBalance.balanceOfScales | currencyAmount: balance.currency}} {{balance.currency | currencyWeight: subTypeBalance.subType }} </td>
                      </tr>
                    </tbody>
                    </table>

                  </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
              <div class="card-body mt-4" *ngIf="branchBalanceList == null">
                <div class="text-muted text-center card-body"><i class="fa fa-exclamation-circle"></i> Henüz bu kasa boş!</div>
              </div>
          </ul>
          <div [ngbNavOutlet]="nav2"></div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="-1">
      <a class="fs-5 custom-align p-1" ngbNavLink>
        <app-case-create-modal></app-case-create-modal>
      </a>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav1"></div>
  </ng-container>
