import { Pipe, PipeTransform } from "@angular/core";
import {
  enumToNumber,
  isNullOrUndefined,
} from "src/app/core/helpers/common-functions";
import { Currency } from "src/app/models/enums/currency";

@Pipe({
  name: "currencyColorCode",
})
export class CurrencyColorCodePipe implements PipeTransform {
  transform(currency: Currency): string {
    let value = enumToNumber(currency);
    switch (value) {
      case Currency.Tl:
        return "#FF4560";
      case Currency.Usd:
        return "#00E396";
      case Currency.Euro:
        return "#008FFB";
      case Currency.Gold:
        return "#FEB019";
      case Currency.Accessories:
        return "#c4ff30";
      case Currency.OtherAccessories:
        return "#38f5c9";
      case Currency.Other:
        return "#f879fc";
      case Currency.Silver:
        return "#a8a8a8"
    }
  }
}
