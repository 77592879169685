import { BaseResponse } from "src/app/core/models/responses/base-response";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AccountTransaction } from "src/app/models/account-transaction/account-transaction";
import { Branch } from "src/app/models/branch/branch";
import { Customer } from "src/app/models/customer/customer";
import { VirmanSummaryRequest } from "src/app/models/customer/virman-summary-request";
import { VirmanSummaryResponse } from "src/app/models/customer/virman-summary-response";
import { AccountTransactionReason } from "src/app/models/enums/account-transaction-reason";
import { AccountTransactionType } from "src/app/models/enums/account-transaction-type";
import { CustomerService } from "src/app/services/customer.service";
import { Observable } from "rxjs";
import { delay, finalize, tap } from "rxjs/operators";

@Component({
  selector: "app-virman-summary",
  templateUrl: "./virman-summary.component.html",
  styleUrls: ["./virman-summary.component.scss"],
})
export class VirmanSummaryComponent implements OnInit {
  currentBranch: Branch = JSON.parse(localStorage.getItem("currentBranch"));
  request: VirmanSummaryRequest = {
    branchId: this.currentBranch.id,
    groupId: null,
    includeSourceCustomerBalance: true,
    includeDestinationCustomerBalance: false,
  };
  transactions: AccountTransaction[] = [];
  Type = AccountTransactionType;
  Reason = AccountTransactionReason;
  operationDate = new Date();
  customer: Customer = {};
  response$: Observable<BaseResponse<VirmanSummaryResponse>>;

  constructor(
    private customerService: CustomerService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getParams();
    this.response$ = this.getVirmanSummaryData();
  }

  getParams() {
    this.route.queryParams.subscribe((params) => {
      this.request.groupId = params.groupId;
      this.request.includeSourceCustomerBalance =
        params.includeSourceCustomerBalance == "true" ? true : false;
      this.request.includeDestinationCustomerBalance =
        params.includeDestinationCustomerBalance == "true" ? true : false;
    });
  }

  getVirmanSummaryData(): Observable<BaseResponse<VirmanSummaryResponse>> {
    return this.customerService.virmanSummary(this.request).pipe(
      tap(() => {
        // Veri yüklendikten sonra ve bileşen DOM'a eklendikten sonra
      }),
      finalize(() => {
        setTimeout(() => {
          window.print();
          window.onafterprint = window.close;
        }, 500);
      })
    );
  }
}
