<ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs">
    <li [ngbNavItem]="1">
        <a ngbNavLink>
            <span class="d-block d-sm-none"><i class="fas fa-calendar-day"></i></span>
            <span class="d-none d-sm-block"><i class="fas fa-calendar-day me-2"></i>Yaklaşan Siparişler</span>
        </a>
        <ng-template ngbNavContent>
            <app-manufacture-orders  [filter]="upcomingFilter"></app-manufacture-orders>
        </ng-template>
    </li>
    <li [ngbNavItem]="2">
        <a ngbNavLink>
            <span class="d-block d-sm-none"><i class="fas fa-calendar-alt"></i></span>
            <span class="d-none d-sm-block"><i class="fas fa-calendar-alt me-2"></i>Hazırlananlar</span>
        </a>
        <ng-template ngbNavContent>
            <app-manufacture-orders  [filter]="inProgressFilter"></app-manufacture-orders>
        </ng-template>
    </li>
    <li [ngbNavItem]="3">
        <a ngbNavLink>
            <span class="d-block d-sm-none"><i class="fas fa-calendar-check"></i></span>
            <span class="d-none d-sm-block"><i class="fas fa-calendar-check me-2"></i>Teslim Alınacaklar</span>
        </a>
        <ng-template ngbNavContent>
            <app-manufacture-orders [filter]="waitForDeliverFilter"></app-manufacture-orders>
        </ng-template>
    </li>
    <li [ngbNavItem]="4">
        <a ngbNavLink>
            <span class="d-block d-sm-none"><i class="fas fa-calendar-times"></i></span>
            <span class="d-none d-sm-block"><i class="fas fa-calendar-times me-2"></i>Tes. Tarih. Geçenler</span>
        </a>
        <ng-template ngbNavContent>
            <app-manufacture-orders [filter]="missesFilter"></app-manufacture-orders>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="nav"></div>