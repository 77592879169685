import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { AccountTransactionReason } from "src/app/models/enums/account-transaction-reason";
import { SelectItem } from "src/app/core/models/responses/select-item";
import { Branch } from "src/app/models/branch/branch";
import { Customer } from "src/app/models/customer/customer";
import { AccountTransactionBatch } from "src/app/models/account-transaction/account-transaction-batch";
import { DataService } from "src/app/services/data.service";
import { AllCurrencyList, Currency } from "src/app/models/enums/currency";
import { CurrencySubTypes } from "src/app/models/enums/currency-sub-types";
import { AccountTransactionType } from "src/app/models/enums/account-transaction-type";
import { AlertService } from "src/app/services/alert.service";
import { CustomerTransaction } from "src/app/models/customer/customer-transaction";
import { AccountTransactionService } from "src/app/services/account-transaction.service";
import { CustomerService } from "src/app/services/customer.service";
import {
  getCurrencySubTypeItem,
  getMilyem,
  isNullOrUndefined,
} from "src/app/core/helpers/common-functions";

@Component({
  selector: "app-account-transaction-create-modal",
  templateUrl: "./account-transaction-create-modal.component.html",
  styleUrls: ["./account-transaction-create-modal.component.scss"],
})
export class AccountTransactionCreateModalComponent implements OnInit {
  @Input() branchPreloaded?: Branch = null;
  @Input() isCustomerTransaction: boolean;
  @Input() customerPreloaded?: Customer = null;
  @Input() currencyPreloaded?: Currency = null;
  @Input() subTypePreloaded?: CurrencySubTypes = null;

  defaultCaseId = 1;
  formModel = {
    type: null,
    branchId: null,
    caseId: this.defaultCaseId,
    customerId: null,
    currency: Currency.Tl,
    subType: null,
    milyem: null,
    amount: null,
    description: null,
  };
  isDebt: boolean = false;
  closeResult = "";
  currencyList = AllCurrencyList;
  subTypes: CurrencySubTypes[] = [];
  selectItemModel: SelectItem[];
  branch: Branch = JSON.parse(localStorage.getItem("currentBranch"));
  request: AccountTransactionBatch = {};
  Currency = Currency;
  caseList: SelectItem[] = [];
  customerTransactionRequest: CustomerTransaction = {};
  isDisabled: boolean = false;
  constructor(
    private modalService: NgbModal,
    public service: AccountTransactionService,
    private customerService: CustomerService,
    private dataService: DataService,
    public activeModal: NgbActiveModal,
    private alert: AlertService
  ) {}

  ngOnInit(): void {
    if (this.currencyPreloaded != null) {
      if (this.subTypePreloaded == null) {
        this.formModel.currency = this.currencyPreloaded;
        this.currencyChanged();
      }
    }
    this.loadCases();
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) =>
        term
          ? this.dataService
              .getCustomer({ name: term, isActive: true })
              .toPromise()
              .then((res) => res.data)
          : []
      )
    );

  selectedCustomer(customer) {
    this.selectItemModel = null;
    this.request.customerId = customer.item.value;
  }

  resultFormatBandListValue(value: SelectItem) {
    return value.text;
  }
  inputFormatBandListValue(value: SelectItem) {
    if (value.text) return value.text;
    return value;
  }

  currencyChanged() {
    let item = getCurrencySubTypeItem(this.formModel.currency);
    this.subTypes = item.subtypes;
    this.formModel.currency = item.currency;
    this.formModel.subType = item.default;
    this.formModel.milyem = item.milyem;
  }

  subTypeChanged() {
    this.formModel.milyem = getMilyem(this.formModel.subType);
  }
  submit() {
    if (isNullOrUndefined(this.formModel.amount)) {
      this.alert.showErrorToast("Miktar giriniz.");
      return;
    }
    if (this.isCustomerTransaction) {
      if (this.formModel.type != 0 && this.formModel.type != 1) {
        this.alert.showErrorToast("Hareket tipini seçiniz.");
        return;
      } else {
        this.request.itemList = [
          {
            description: this.formModel.description,
            amount: this.formModel.amount,
            type: parseInt(String(this.formModel.type)),
          },
        ];
      }
      this.request.customerBranchId = this.branch.id;
      if (this.customerPreloaded)
        this.request.customerId = this.customerPreloaded.id;
    }

    if (!this.isCustomerTransaction) {
      this.request.itemList = [
        {
          amount: this.formModel.amount,
          description: this.formModel.description,
          type: AccountTransactionType.Receivable,
        },
      ];
      this.request.branchId = this.branch.id;
      if (this.branchPreloaded) this.request.branchId = this.branchPreloaded.id;
    }

    if (this.currencyPreloaded == null) {
      this.request.itemList[0].currency = parseInt(
        String(this.formModel.currency)
      );
      this.request.itemList[0].subType = parseInt(
        String(this.formModel.subType)
      );
    }

    if (this.currencyPreloaded != null) {
      this.request.itemList[0].currency = this.currencyPreloaded;
      if (this.subTypePreloaded == null)
        this.request.itemList[0].subType = parseInt(
          String(this.formModel.subType)
        );
    }

    this.request.caseId = this.formModel.caseId;

    this.request.itemList.forEach((item) => {
      if (item.caseId == 0 || item.caseId == null) {
        item.caseId = this.formModel.caseId;
        item.reason = AccountTransactionReason.BranchMoneyEntry;
        item.milyem = this.formModel.milyem;
      }
    });

    this.isDisabled = true;
    this.service
      .createBatch(this.request)
      .toPromise()
      .then((res) => {
        this.isDisabled = false;
        if (res.isSuccess) {
          this.modalService.dismissAll();
          this.alert.showSuccess("Hesap hareketi eklendi!");
          this.service.refreshIndex.next();
          this.customerService.refreshIndex.next();
        }
        this.formModel = {
          type: null,
          branchId: null,
          caseId: this.defaultCaseId,
          customerId: null,
          currency: Currency.Tl,
          subType: null,
          milyem: null,
          amount: null,
          description: null,
        };
        this.request = {};
        this.subTypes = [];
      })
      .catch((err) => (this.isDisabled = false));
  }

  loadCases() {
    this.dataService
      .getCase({ branchId: this.branch.id })
      .toPromise()
      .then((res) => (this.caseList = res.data));
  }

  addTransaction() {
    if (isNullOrUndefined(this.formModel.amount)) {
      this.alert.showErrorToast("Miktar giriniz.");
      return;
    }

    if (
      this.formModel.currency == Currency.Gold &&
      isNullOrUndefined(this.formModel.milyem)
    ) {
      this.alert.showErrorToast("Milyem giriniz.");
      return;
    }
    if (this.customerPreloaded)
      this.customerTransactionRequest.customerId = this.customerPreloaded.id;

    this.customerTransactionRequest.branchId = this.branch.id;
    this.customerTransactionRequest.currency = Number(
      this.formModel.currency.toString()
    );
    if (this.formModel.subType != undefined || this.formModel.subType != undefined) {
      this.customerTransactionRequest.subType = Number(
        this.formModel.subType.toString()
      );
    }

    this.customerTransactionRequest.type = this.formModel.type;

    if (
      this.customerTransactionRequest.type != 0 &&
      this.customerTransactionRequest.type != 1
    ) {
      this.alert.showErrorToast("Hareket tipini seçiniz!");
      return;
    }

    this.customerTransactionRequest.currency == Currency.Gold
      ? (this.customerTransactionRequest.milyem = this.formModel.milyem)
      : (this.customerTransactionRequest.milyem = null);
    this.customerTransactionRequest.amount = this.formModel.amount;
    this.customerTransactionRequest.description = this.formModel.description;
    let message;
    if (
      this.customerTransactionRequest.type == AccountTransactionType.Receivable
    ) {
      this.customerTransactionRequest.reason = AccountTransactionReason.Deposit;
      message = "Alacak";
    }
    if (this.customerTransactionRequest.type == AccountTransactionType.Debt) {
      this.customerTransactionRequest.reason =
        AccountTransactionReason.Withdraw;
      message = "Borç";
    }
    this.customerService
      .createTransaction(this.customerTransactionRequest)
      .toPromise()
      .then((res) => {
        if (res.isSuccess) {
          this.modalService.dismissAll();
          this.alert.showSuccess(message + " eklendi!");
          this.service.refreshIndex.next();
          this.customerService.refreshIndex.next();
        }
        this.formModel = {
          type: null,
          branchId: null,
          caseId: this.defaultCaseId,
          customerId: null,
          currency: Currency.Tl,
          subType: null,
          milyem: null,
          amount: null,
          description: null,
        };
        this.customerTransactionRequest = {};
        this.subTypes = [];
      });
  }
}
