<div *ngIf="customer">
  <div class="card mb-3">
    <div class="pt-2 px-3 rounded-top table-danger d-flex" [ngClass]="{'pb-2': !showCustomerName}">
      <div class="my-0 mt-1 flex-grow-1"><span class="fs-5 table-danger fw-bold text-danger">BAKİYE</span><span
          triggers="hover" ngbTooltip="Bu tarihte gerçekleşen işlem sonrası bakiyeyi gösterir." style="font-size: 13px"
          *ngIf="customer.balanceEndDate"> ({{customer.balanceEndDate | date: 'dd.MM.yyyy HH:mm'}}) </span></div>
      <div *ngIf="!showCustomerName">
        <button class="btn btn-sm btn-primary me-2" (click)="openCollectionCreateModal()"> <i
            class="fas fa-receipt me-1"></i> Tahsilat</button>
        <button class="btn btn-sm btn-warning me-2" (click)="openCustomerTransactionCreateModal()"> <i
            class="fas fa-hand-paper me-1"></i> Borç/Alacak</button>
        <button class="btn btn-sm btn-danger" triggers="hover" ngbTooltip="Yazdır"
          (click)="goToLink('/receipts/customer-balance?id='+ customer.id)"> <i class="fas fa-print"></i></button>
        <div>
        </div>
      </div>
    </div>
    <div *ngIf="showCustomerName" class="pb-2 px-3 rounded-top table-danger">
      <div class="row d-flex align-items-end">
        <div class="col-md-3">
          <div class="customer-name-container"><span triggers="hover" [ngbTooltip]="customer.name"
              class="fs-6 table-danger fw-bold text-secondary"><i
                class="fas fa-user-tie me-2"></i>{{customer.name}}</span></div>
        </div>
        <div class="col-md-9 text-end">
          <button class="btn btn-sm btn-primary me-2" (click)="openCollectionCreateModal()"> <i
              class="fas fa-receipt me-1"></i> Tahsilat</button>
          <button class="btn btn-sm btn-warning me-2" (click)="openCustomerTransactionCreateModal()"> <i
              class="fas fa-hand-paper me-1"></i> Borç/Alacak</button>
          <button class="btn btn-sm btn-danger" triggers="hover" ngbTooltip="Yazdır"
            (click)="goToLink('/receipts/customer-balance?id='+ customer.id)"> <i class="fas fa-print"></i></button>
        </div>
      </div>



    </div>
    <div class="card-body p-0">
      <div class="table-responsive">
        <table class="table table-bordered mb-0">
          <thead>
            <tr>
              <th class="table-primary">Birim</th>
              <th class="table-danger">Borç</th>
              <th class="table-success">Alacak</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let balanceDTO of customer.mergedBalanceList | keyvalue">
              <ng-container *ngIf="balanceDTO.value != 0">
                <td> HAS </td>
                <td class="table-danger text-end"><span *ngIf="balanceDTO.value < 0">{{ balanceDTO.value * -1 |
                    currencyAmount: balanceDTO.key: null: true}}</span></td>
                <td class="table-success text-end"><span *ngIf="balanceDTO.value > 0">{{ balanceDTO.value |
                    currencyAmount: balanceDTO.key : null: true}}</span></td>
              </ng-container>
            </tr>
            <tr *ngFor="let balanceDTO of customer.balanceList">
              <td> <span *ngIf="balanceDTO.subType != undefined"> {{balanceDTO.subType | subTypeDisplay }}
                  ({{balanceDTO.currency | customCurrency}})</span> <span *ngIf="balanceDTO.subType == null"> {{
                  balanceDTO.currency | customCurrency }} </span> </td>
              <td class="table-danger text-end"><span *ngIf="balanceDTO.balance < 0">{{balanceDTO.balance * -1 |
                  currencyAmount: balanceDTO.key : null: true}}</span></td>
              <td class="table-success text-end"><span *ngIf="balanceDTO.balance > 0">{{balanceDTO.balance |
                  currencyAmount: balanceDTO.key: null: true}}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
