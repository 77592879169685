<div class="row d-flex">
<div class="col-md-3">
<div class="d-flex justify-content-end">
    <div triggers="hover" triggers="hover" triggers="hover" ngbTooltip="Hareket Ekle" [autoClose]="true" container="body" class="transaction-add align-btn"> 
        <span  (click)="openAccountTransactionAddModal(Currency.Tl)"> <i class="fas fa-plus"></i></span>
    </div>
</div>
<div class="card mini-stats-wid">
    <div class="card-header bg-white border-bottom border-danger">
        <h4 class="text-danger mt-2">
            Türk Lirası
            <i class="fas fa-lira-sign float-end me-2"></i> 
        </h4>
    </div>
    <div class="card-body">
        <div class="media">
            <div class="media-body">
                <h5 class="mb-0 d-flex flex-column gap-2"> 
                    <span class="d-flex justify-content-between">
                        <span triggers="hover" triggers="hover" ngbTooltip="Kasa">K:</span>
                        <span *ngIf="tlBalance">{{ tlBalance.balance| currencyAmount: Currency.Tl }}</span>
                        <span *ngIf="!tlBalance">0</span>
                    </span>
                    <span class="d-flex justify-content-between" [ngClass]="setCustomerBalanceColor(tlBalance.customersBalance)">
                        <span triggers="hover" triggers="hover" ngbTooltip="Müşteri">M:</span>
                        <span *ngIf="tlBalance">{{ tlBalance.customersBalance| currencyAmount: Currency.Tl }}</span>
                        <span *ngIf="!tlBalance">0</span>
                    </span>
                    <span class="d-flex justify-content-between">
                        <span triggers="hover" triggers="hover" ngbTooltip="Toplam">T:</span>
                        <span *ngIf="tlBalance">{{ tlBalance.finalBalance | currencyAmount: Currency.Tl }}</span>
                        <span *ngIf="!tlBalance">0</span>
                    </span>
                </h5>
            </div>
        </div>
    </div>
</div>  
</div>
<div class="col-md-3">
<div class="d-flex justify-content-end">
    <div triggers="hover" triggers="hover" ngbTooltip="Hareket Ekle" [autoClose]="true" container="body" class="transaction-add align-btn"> 
        <span  (click)="openAccountTransactionAddModal(Currency.Gold)"> <i class="fas fa-plus"></i></span>
    </div>
</div>
<div class="card mini-stats-wid">
    <div class="card-header bg-white border-bottom border-warning">
        <h4 class="text-warning mt-2">
            Altın
            <i class="fas fa-coins float-end me-2"></i> 
        </h4>
    </div>
    <div class="card-body">
        <div class="media">
            <div class="media-body">
                <h5 class="mb-0 d-flex flex-column gap-2"> 
                    <span class="d-flex justify-content-between">
                        <span triggers="hover" triggers="hover" ngbTooltip="Kasa">K:</span>
                        <span *ngIf="goldBalance">{{ goldBalance.balance | currencyAmount: Currency.Gold }}</span>
                        <span *ngIf="!goldBalance">0</span>
                    </span>
                    <span class="d-flex justify-content-between"
                        [ngClass]="goldBalance.customersBalance >= 0 ? 'text-success': 'text-danger'">
                        <span triggers="hover" triggers="hover" ngbTooltip="Müşteri">M:</span>
                        <span>{{ goldBalance.customersBalance | currencyAmount: Currency.Gold }}</span>
                        <span *ngIf="!goldBalance">0</span>

                    </span>
                    <span class="d-flex justify-content-between">
                        <span triggers="hover" triggers="hover" ngbTooltip="Toplam">T:</span>
                        <span>{{ goldBalance.finalBalance | currencyAmount: Currency.Gold }}</span>
                        <span *ngIf="!goldBalance">0</span>
                    </span>
                </h5>
            </div>
        </div>
    </div>
</div>  
</div>
<div class="col-md-3">
<div class="d-flex justify-content-end">
    <div triggers="hover" triggers="hover" ngbTooltip="Hareket Ekle" [autoClose]="true" container="body" class="transaction-add align-btn"> 
        <span  (click)="openAccountTransactionAddModal(Currency.Usd)"> <i class="fas fa-plus"></i></span>
    </div>
</div>
<div class="card mini-stats-wid">
    <div class="card-header bg-white border-bottom border-success">
        <h4 class="text-success mt-2">
            Dolar
            <i class="fas fa-dollar-sign mb-0 float-end me-2"></i> 
        </h4>
    </div>
    <div class="card-body">
        <div class="media">
            <div class="media-body">
                <h5 class="mb-0 d-flex flex-column gap-2"> 
                    <span class="d-flex justify-content-between">
                        <span triggers="hover" triggers="hover" ngbTooltip="Kasa">K:</span>
                        <span *ngIf="usdBalance">{{ usdBalance.balance | currencyAmount: Currency.Usd }}</span>
                        <span *ngIf="!usdBalance">0</span>
                    </span>
                    <span class="d-flex justify-content-between" [ngClass]="usdBalance.customersBalance >= 0 ? 'text-success': 'text-danger'">
                        <span triggers="hover" triggers="hover" ngbTooltip="Müşteri">M:</span>
                        <span>{{ usdBalance.customersBalance | currencyAmount: Currency.Usd }}</span>
                        <span *ngIf="!usdBalance">0</span>
                    </span>
                    <span class="d-flex justify-content-between">
                        <span triggers="hover" triggers="hover" ngbTooltip="Toplam">T:</span>
                        <span>{{ usdBalance.finalBalance | currencyAmount: Currency.Usd }}</span>
                        <span *ngIf="!usdBalance">0</span>
                    </span>
                </h5>
            </div>
        </div>
    </div>
</div>  
</div>
<div class="col-md-3">
<div class="d-flex justify-content-end">
    <div triggers="hover" triggers="hover" ngbTooltip="Hareket Ekle" [autoClose]="true" container="body" class="transaction-add align-btn"> 
        <span  (click)="openAccountTransactionAddModal(Currency.Euro)"> <i class="fas fa-plus"></i></span>
    </div>
</div>
<div class="card mini-stats-wid">
    <div class="card-header bg-white border-bottom border-info">
        <h4 class="text-info mt-2">
            Euro
            <i class="fas fa-euro-sign float-end me-2"></i> 
        </h4>
    </div>
    <div class="card-body">
        <div class="media">
            <div class="media-body">
                <h5 class="mb-0 d-flex flex-column gap-2"> 
                    <span class="d-flex justify-content-between">
                        <span triggers="hover" triggers="hover" ngbTooltip="Kasa">K:</span>
                        <span *ngIf="euroBalance">{{ euroBalance.balance | currencyAmount: Currency.Euro }}</span>
                        <span *ngIf="!euroBalance">0</span>
                    </span>
                    <span class="d-flex justify-content-between"
                        [ngClass]="euroBalance.customersBalance >= 0 ? 'text-success': 'text-danger'">
                        <span triggers="hover" triggers="hover" ngbTooltip="Müşteri">M:</span>
                        <span>{{ euroBalance.customersBalance | currencyAmount: Currency.Euro }}</span>
                        <span *ngIf="!euroBalance">0</span>
                    </span>
                    <span class="d-flex justify-content-between">
                        <span triggers="hover" triggers="hover" ngbTooltip="Toplam">T:</span>
                        <span>{{ euroBalance.finalBalance | currencyAmount: Currency.Euro }}</span>
                        <span *ngIf="!euroBalance">0</span>
                    </span>
                </h5>
            </div>
        </div>
    </div>
</div>  
</div>
</div>