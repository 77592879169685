import { Component, Input, OnInit } from '@angular/core';
import { ManufactureOrderState } from 'src/app/models/enums/manufacture-order-state';

@Component({
  selector: 'app-manufacture-order-state',
  templateUrl: './manufacture-order-state.component.html',
  styleUrls: ['./manufacture-order-state.component.scss']
})
export class ManufactureOrderStateComponent implements OnInit {
  @Input() state: ManufactureOrderState
  ManufactureOrderState = ManufactureOrderState 
  constructor() { }

  ngOnInit(): void {
  }

}
